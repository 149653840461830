<template>
  <v-app>
    <div v-if="!criticalError">
      <Loading :ready="ready" />
      <v-navigation-drawer v-model="drawer" app clipped color="navigation" v-if="isAuthenticated"
        :mini-variant="miniDrawer && $vuetify.breakpoint.lgAndUp" width="280">
        <Navigation :mini-drawer="miniDrawer" />
      </v-navigation-drawer>

      <v-app-bar elevation="1" app clipped-left color="navigation" v-on.native.stop="{
      mousedown: startTimer,
      mouseup: stopTimer,
      mouseleave: stopTimer
    }" v-touch="{ right: () => startTimer() }">
        <v-app-bar-nav-icon @click="toggleDrawer" v-if="isAuthenticated"></v-app-bar-nav-icon>
        <div class="d-flex align-center">
          <router-link to="/">
            <v-img alt="Kingston Logo" class="shrink mr-2 hidden-xs-only" width="200" :src="require(dark
      ? '@/assets/ktc-logo-white-hr.png'
      : '@/assets/ktc-logo-black-hr.png')
      " transition="scale-transition" />
          </router-link>
          <router-link to="/" custom v-slot="{ navigate }">
            <v-toolbar-title style="cursor: pointer" @click="navigate" data-public>
              <v-chip v-if="envText" label small color="primary">{{
      envText
    }}</v-chip>
              Travel Req
              <v-chip small color="primary">
                {{ domain }}
              </v-chip>
            </v-toolbar-title>
          </router-link>
        </div>

        <v-spacer></v-spacer>

        <v-btn v-if="authState && authState.isAuthenticated" to="/profile" fab small elevation="0">
          <v-avatar size="36" data-private>
            <img v-if="profilePicURL" v-bind:src="'data:image/jpeg;base64,' + profilePicURL" />
            <v-icon v-else>mdi-account</v-icon>
          </v-avatar>
        </v-btn>
        <v-btn v-else text @click="login">Login</v-btn>
      </v-app-bar>

      <v-main>
        <v-container :fluid="$vuetify.breakpoint.smAndDown">
          <v-row class="justify-center">
            <!-- <v-col class="hidden-sm-and-down shrink pr-1" cols="2" v-if="isAuthenticated">
            <v-sheet rounded="lg" class="elevation-4">
              <Navigation />
            </v-sheet>
          </v-col> -->

            <v-col cols="12" :class="$vuetify.breakpoint.mdAndUp ? '' : 'pa-0'">
              <v-sheet class="elevation-4" min-height="calc(100vh - 150px)"
                :rounded="$vuetify.breakpoint.mdAndUp ? 'lg' : '0'" style="display: grid">
                <v-expand-transition mode="out-in">
                  <router-view v-if="ready || anonymous" />

                  <v-skeleton-loader v-else type="image, article, table"></v-skeleton-loader>
                </v-expand-transition>
              </v-sheet>
            </v-col>

            <v-col cols="12" class="text-center text-caption grey--text" data-public>©{{ year }} - Kingston Technology
              Corporation. All Rights
              Reserved.</v-col>
          </v-row>
        </v-container>
      </v-main>

      <!-- <v-btn fab large dark bottom right fixed>
      <v-icon>mdi-web</v-icon>
    </v-btn> -->

      <Region v-if="ready" />
      <Snackbar />
      <Sync v-if="ready" />
    </div>
    <ErrorAlert />
  </v-app>
</template>

<style>
#app {
  background-color: var(--v-background-base);
}

:root {
  --sat: env(safe-area-inset-top);
  --sar: env(safe-area-inset-right);
  --sab: env(safe-area-inset-bottom);
  /* THIS ONE GETS US THE HOME BAR HEIGHT */
  --sal: env(safe-area-inset-left);
}

#app-container {
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
}

.theme--light.v-data-table tbody tr:nth-of-type(even) {
  background-color: rgba(0, 0, 0, 0.09);
}

.theme--dark.v-data-table tbody tr:nth-of-type(even) {
  background-color: rgba(0, 0, 0, 0.2);
}

.theme--dark input {
  color-scheme: dark;
}

.v-card__actions {
  overflow-x: auto;
}
</style>
<script>
import axios from "axios"
import { mapState, mapGetters } from "vuex"

import Loading from "@/components/Loading"
import Navigation from "@/components/Navigation"
import ErrorAlert from "@/components/ErrorAlert"
import Snackbar from "@/components/Snackbar"
import Region from "@/components/Region"
import Sync from "@/components/sync/Sync"

export default {
  name: "App",

  components: {
    Loading,
    Navigation,
    Snackbar,
    ErrorAlert,
    Region,
    Sync
  },

  data: () => ({
    drawer: false,
    miniDrawer: false,
    profilePic: null,
    year: new Date().getFullYear(),
    timer: null,
    profilePicURL: null,
    initiated: false
  }),
  methods: {
    toggleDrawer() {
      if (this.$vuetify.breakpoint.mdAndDown) {
        this.drawer = !this.drawer
      } else {
        this.miniDrawer = !this.miniDrawer
        localStorage.setItem("mini-drawer", this.miniDrawer)
      }
    },
    async login() {
      await this.$auth.signInWithRedirect({ originalUri: "/" })
    },
    async logout() {
      await this.$auth.signOut()
    },
    async init() {
      if (this.initiated) return
      this.initiated = true
      console.log("Logging in...")
      await this.$store
        .dispatch("getUserInfo", this.$auth)
        .then(async (response) => {
          console.log("Logged in as", this.userInfo.email)

          if (this.userInfo.email) {
            await this.$store.dispatch("getPermissions").then((response) => {
              if (
                response.code === "ERR_NETWORK" ||
                response.code === "ERR_BAD_RESPONSE"
              ) {
                this.$store.commit("setLoadingError", true)
              }
            })

            await this.$store.dispatch("getUserPhoto").then((response) => {
              this.profilePicURL = response.data
            }).catch((error) => {
              console.error("Error on getting user photo", error)
            })
          }
        })
        .catch(async (error) => {
          console.error("Error on getting user info", error)
          // If error is 401, clear local session and redirect to Okta logout
          if (error.xhr && error.xhr.status === 401) {
            // Read idToken before local session is cleared
            const idToken = await this.$auth.getIdToken()
            await this.$auth.signOut()
            // Clear remote session
            window.location.href = `${process.env.VUE_APP_OKTA_ISSUER}/oauth2/v1/logout?id_token_hint=${idToken}&post_logout_redirect_uri=${window.location.origin}${process.env.VUE_APP_PUBLIC_PATH}logged-out`
          } else {
            // this.$store.commit("showError", {
            //   message: `Your session has timed out. Please log back in.`,
            //   critical: true,
            //   timer: 0,
            //   buttonText: "Log in"
            // })
            this.$store.commit("showError", {
              message: `Travel Req is down for maintenance.`,
              critical: true,
              buttonText: "Reload"
            })
          }
        })
    },
    startTimer(e) {
      var _this = this
      if (e && e.which == 1) {
        this.timer = setTimeout((x) => {
          this.$store.commit("toggleDarkMode")
          // this.$vuetify.theme.dark = !this.$vuetify.theme.dark
        }, 1000)
      } else if (e == null) {
        this.$store.commit("toggleDarkMode")
        // this.this.$vuetify.theme.dark = !this.$vuetify.theme.dark
      }
    },
    stopTimer() {
      clearTimeout(this.timer)
    }
  },

  computed: {
    ...mapState(["appVersion", "userInfo", "region", "domain", "anonymous"]),
    ...mapGetters(["ready", "dark", "criticalError"]),
    isAuthenticated() {
      return this.authState.isAuthenticated
    },
    // profilePicURL() {
    //   return this.userInfo.thumbnailPhoto
    // }
  },
  watch: {
    async isAuthenticated(val) {
      if (val) {
        await this.init()
      }
    },
    dark(dark) {
      this.$vuetify.theme.dark = this.dark
    },
    ready(val) {
      if (val && this.$route.name !== "home") {
        // Only run if not on home page, otherwise homepage will make same call
        if (this.has([this.permissions.manager])) {
          this.$store.dispatch("updateAuthorizeCount")
        }
        if (this.has([this.permissions.generalAffairsManager])) {
          this.$store.dispatch("updateApproveCount")
        }
        if (this.has([this.permissions.travelCoordinator])) {
          this.$store.dispatch("travel/getProcessCount")
        }
        if (this.has([this.permissions.generalAffairsHandler])) {
          this.$store.dispatch("transportation/getProcessCount")
          this.$store.dispatch("fvAccommodation/getProcessCount")
        }
      }
    }
  },
  async created() {
    console.log(`${process.env.VUE_APP_NAME} starting`)
    this.$vuetify.theme.dark = this.dark

    axios.interceptors.request.use(
      async (config) => {
        var token = await this.$auth.getIdToken()
        config.headers.Authorization = "Bearer " + token
        return config
      },
      (error) => {
        // Do something with request error
        return Promise.reject(error)
      }
    )

    var version = (await this.$store.dispatch("getMinVersion")).data
    if (version === "maintenance") {
      this.$store.commit("showError", {
        message: `Travel Req is down for maintenance`,
        critical: true,
        buttonText: "Reload"
      })
    } else if (version > this.appVersion) {
      console.log("Version Update Required")
      this.$store.commit("showError", {
        message: `Update Required`,
        critical: true,
        buttonText: "Update",
        timer: 3
      })
    } else {
      if (this.isAuthenticated) {
        this.init()
      }

      this.miniDrawer = localStorage.getItem("mini-drawer") === "true" || false
      if (this.$vuetify.breakpoint.lgAndUp) this.drawer = true

      this.$store.commit("setAwake")
    }
  }
}
</script>
