import Vue from "vue"
import VueRouter from "vue-router"

import HomeView from "../views/HomeView.vue"
import LostView from "../views/LostView.vue"
import LoggedOutView from "../views/LoggedOutView.vue"
// import ComingSoonView from "../views/ComingSoonView.vue"

// import NewTravelReqView from "../views/NewTravelReqView.vue"

import store from "../store"
import i18n from "../i18n"

import { OktaAuth } from "@okta/okta-auth-js"
import OktaVue from "@okta/okta-vue"
import OktaLoginCallback from "./OktaLoginCallback"
import oktaConfig from "../../okta.config"

import permissions from "../data/permissions"

const oktaAuth = new OktaAuth(oktaConfig)

Vue.use(VueRouter)

Vue.use(OktaVue, { oktaAuth })

const routes = [
  { path: "/login/callback", name: "okta", component: OktaLoginCallback },
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/new",
    name: "new",
    component: () =>
      import(/* webpackChunkName: "new" */ "../views/travel/NewView.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/edit/:id",
    name: "edit",
    component: () =>
      import(/* webpackChunkName: "edit" */ "../views/travel/EditView.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/new-transportation",
    name: "new-transportation",
    component: () =>
      import(
        /* webpackChunkName: "new-transportation" */ "../views/transportation/NewView.vue"
      ),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/edit-transportation/:id",
    name: "edit-transportation",
    component: () =>
      import(
        /* webpackChunkName: "edit-transportation" */ "../views/transportation/EditView.vue"
      ),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/edit-transportation-batch/:id",
    name: "edit-transportation-batch",
    component: () =>
      import(
        /* webpackChunkName: "edit-transportation-batch" */ "../views/transportation/EditBatchView.vue"
      ),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/new-fv-accommodation",
    name: "new-fv-accommodation",
    component: () =>
      import(
        /* webpackChunkName: "new-fvAccommodation" */ "../views/fvAccommodation/NewView.vue"
      ),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/edit-fv-accommodation/:id",
    name: "edit-fv-accommodation",
    component: () =>
      import(
        /* webpackChunkName: "edit-fvAccommodation" */ "../views/fvAccommodation/EditView.vue"
      ),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/info",
    name: "info",
    // route level code-splitting
    // this generates a separate chunk (info.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "info" */ "../views/InfoView.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/archive/:mode?/:id?",
    name: "archive",
    component: () =>
      import(/* webpackChunkName: "archive" */ "../views/ArchiveView.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/authorize/:mode?/:id?",
    name: "authorize",
    component: () =>
      import(/* webpackChunkName: "authorize" */ "../views/AuthorizeView.vue"),
    meta: {
      requiresAuth: true,
      permissions: [
        permissions.admin,
        permissions.manager,
        permissions.generalAffairsManager
      ]
    }
  },
  {
    path: "/search/:mode?/:id?",
    name: "search",
    component: () =>
      import(/* webpackChunkName: "search" */ "../views/SearchView.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/report",
    name: "report",
    component: () =>
      import(/* webpackChunkName: "report" */ "../views/ReportView.vue"),
    meta: {
      requiresAuth: true,
      permissions: [permissions.admin, permissions.accountsPayable, permissions.ukReport]
    }
  },
  {
    path: "/manage",
    name: "manage",
    component: () =>
      import(/* webpackChunkName: "manage" */ "../views/ManageView.vue"),
    meta: {
      requiresAuth: true,
      permissions: [permissions.admin]
    }
  },
  {
    path: "/admin",
    name: "admin",
    component: () =>
      import(/* webpackChunkName: "admin" */ "../views/AdminView.vue"),
    meta: {
      requiresAuth: true,
      permissions: [permissions.admin]
    }
  },
  {
    path: "/profile",
    name: "profile",
    component: () =>
      import(/* webpackChunkName: "profile" */ "../views/ProfileView.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/settings",
    name: "settings",
    component: () =>
      import(/* webpackChunkName: "settings" */ "../views/SettingsView.vue"),
    meta: {
      requiresAuth: true
    }
  },
  
  {
    path: "/status",
    name: "status",
    component: () =>
      import(/* webpackChunkName: "status" */ "../views/SystemStatus.vue")
  },
  {
    path: "/logged-out",
    name: "logged-out",
    component: LoggedOutView
  },
  {
    path: "*",
    name: "404",
    component: LostView
  }
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
})

function waitInit() {
  return new Promise((resolve) => {
    function checkInit() {
      setTimeout(() => {
        if (
          oktaAuth.authStateManager._authState.isAuthenticated &&
          !store.state.init
        ) {
          checkInit()
        } else {
          resolve()
        }
      }, 100)
    }
    checkInit()
  })
}

var firstRoute = false
router.beforeEach(async (to, from, next) => {
  // Get redirect working after okta login
  if (!firstRoute && from.name === null && to.name !== "okta") {
    localStorage.setItem("redirectURL", to.fullPath)
    firstRoute = true
  }

  // Wait for user info before continuing logic
  if (!store.state.anonymous && !store.state.init) await waitInit()

  store.commit("setAnonymous", !to.meta.requiresAuth)

  document.title = `${to.name ? `${i18n.t(`routes.${to.name}`)} - ` : ""} ${
    process.env.VUE_APP_ENV !== "PRD" ? "[" + process.env.VUE_APP_ENV + "]" : ""
  } ${process.env.VUE_APP_NAME}`

  if (to.meta.permissions && to.meta.permissions.length > 0) {
    let isAllowed = store.state.userPermissions.some((p) =>
      to.meta.permissions.includes(p)
    )

    if (!isAllowed) return next("/")
  }
  next()
})

router.afterEach(async (to, from) => {
  // Redirect after okta login
  if (from.name === "okta" && to.name === "home" || to.name === "logged-out") {
    var redirectURL = localStorage.getItem("redirectURL")
    localStorage.removeItem("redirectURL")
    if (redirectURL) router.push(redirectURL)
  }
})

// Suppress duplicate navigation errors
const originalPush = router.push
router.push = function push(location, onResolve, onReject)
{
    if (onResolve || onReject) {
        return originalPush.call(this, location, onResolve, onReject)
    }
 
    return originalPush.call(this, location).catch((err) => {
        if (VueRouter.isNavigationFailure(err, VueRouter.NavigationFailureType.duplicated)) {
            return err
        }
   
        return Promise.reject(err)
    })
}

export default router
