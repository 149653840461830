import Vue from "vue"
import Vuex from "vuex"

import axios from "axios"
import domains from "@/data/domains"

import travel from "./modules/travel"
import transportation from "./modules/transportation"
import fvAccommodation from "./modules/fvAccommodation"
import lookup from "./modules/lookup"
import employee from "./modules/employee"
import attachment from "./modules/attachment"

import * as signalR from "@microsoft/signalr"

import LogRocket from "logrocket"

Vue.use(Vuex)

axios.defaults.baseURL = process.env.VUE_APP_ROOT_API
axios.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    console.log(error)
    if (
      error.code === "ERR_NETWORK" ||
      (error.response && error.response.status === 503)
    ) {
      store.commit("showError", {
        message: `Travel Req is down for maintenance.`,
        critical: true,
        buttonText: "Reload"
      })
    }
    // else if (error.response && error.response.status === 401) {
    //   store.commit("showError", {
    //     message: `Your session has timed out. Please log back in.`,
    //     critical: true,
    //     timer: 0,
    //     buttonText: "Log in"
    //   });
    // }
    else if (error.response && error.response.status === 401) {
      store.commit("showError", {
        message: `You're not allowed to do that!`
      })
    } else {
      store.commit("showError", {
        message: "Uh oh something went wrong"
      })
    }

    return Promise.reject(error)
  }
)

const state = {
  appVersion: process.env.VUE_APP_VERSION,
  syncConnection: null,
  awake: false,
  init: false,
  loadingError: false,
  snackbar: {
    open: false,
    message: ""
  },
  errorAlert: {
    open: false,
    message: "",
    critical: null
  },
  fetchingUser: false,
  userInfo: {},
  userPermissions: [],
  region: JSON.parse(localStorage.getItem("region")) || "FV",
  domain: JSON.parse(localStorage.getItem("domain")) || "US",
  theme: JSON.parse(
    localStorage.getItem("theme") || JSON.stringify({ dark: true })
  ),
  anonymous: null
}

const getters = {
  ready(state, getters) {
    return state.userInfo.email && state.init && !getters.criticalError
  },
  dark(state) {
    return state.theme.dark
  },
  authorizeCount(state, getters, rootState) {
    return (
      rootState.travel.authorizeCount + rootState.transportation.authorizeCount + rootState.fvAccommodation.authorizeCount
    )
  },
  searchCount(state, getters, rootState) {
    return (
      rootState.travel.processCount +
      rootState.transportation.approveCount +
      rootState.transportation.processCount +
      rootState.fvAccommodation.approveCount +
      rootState.fvAccommodation.processCount 
    )
  },
  criticalError(state) {
    return state.errorAlert.critical === true
  }
}

const mutations = {
  setAnonymous(state, anon) {
    state.anonymous = anon
  },
  setAwake(state) {
    state.awake = true
  },
  setPermissions(state, permissions) {
    state.userPermissions = permissions
    state.init = true
  },
  showSnackbar(state, payload) {
    state.snackbar = {
      open: true,
      message: payload.message,
      color: payload.color
    }
  },
  showError(state, payload) {
    state.errorAlert = {
      open: true,
      message: payload.message,
      critical: payload.critical,
      timer: payload.timer,
      buttonText: payload.buttonText || "Reload",
      callback:
        payload.callback ||
        function () {
          window.location.reload()
        }
    }
  },
  setUserInfo(state, userInfo) {
    state.userInfo = userInfo
    LogRocket.identify(userInfo.email, {
      name: userInfo.name,
      username: userInfo.nickname,

      zoneinfo: userInfo.zoneinfo,

      environment: process.env.VUE_APP_ENV,
      release: state.appVersion
    })
  },
  clearUserInfo(state) {
    state.userInfo = {}
  },
  setFetchingUser(state, fetching) {
    state.fetchingUser = fetching
  },
  setDomain(state, domain) {
    state.region = domains.find((x) => x.domain === domain).region
    state.domain = domain
    localStorage.setItem("region", JSON.stringify(state.region))
    localStorage.setItem("domain", JSON.stringify(state.domain))
  },
  setLoadingError(state, error) {
    state.loadingError = error
  },
  toggleDarkMode(state) {
    state.theme.dark = !state.theme.dark
    localStorage.setItem("theme", JSON.stringify(state.theme))
  },
  createSync(state, auth) {
    state.syncConnection = new signalR.HubConnectionBuilder()
      .withUrl(process.env.VUE_APP_SYNC_URL, {
        accessTokenFactory: async () => await auth.getIdToken()
      })
      .withAutomaticReconnect()
      .configureLogging(
        process.env.VUE_APP_ENV === "PRD"
          ? signalR.LogLevel.Error
          : signalR.LogLevel.Information
      )
      .build()
  }
}

const actions = {
  async hello() {
    return await axios.get(`Info/hello`)
  },
  async getMinVersion() {
    return await axios.get(`Info/min-version`, {
      params: { version: process.env.VUE_APP_MINSERVERVERSION }
    })
  },
  async getPermissions({ commit }) {
    return axios.get("User/permissions").then((response) => {
      commit("setPermissions", response.data)
      return response
    })
  },
  async getUserInfo({ commit }, auth) {
    commit("setFetchingUser", true)
    var userInfo = await auth.getUser()
    commit("setFetchingUser", false)
    commit("createSync", auth)

    return new Promise((resolve, reject) => {
      commit("setUserInfo", userInfo)
      resolve(userInfo)
    })
  },
  async getUserPhoto({ commit }, email) {
    return axios.get(`User/user-photo`)
  },
  async updateAuthorizeCount({ dispatch }) {
    return Promise.all([
      dispatch("travel/getAuthorizeCount", { root: true }),
      dispatch("transportation/getAuthorizeCount", { root: true }),
      dispatch("fvAccommodation/getAuthorizeCount", { root: true })
    ])
  },
  async updateApproveCount({ dispatch }) {
    return Promise.all([
      dispatch("transportation/getApproveCount", { root: true }),
      dispatch("fvAccommodation/getApproveCount", { root: true })
    ])
  }
}

const store = new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
  modules: {
    travel,
    transportation,
    fvAccommodation,
    lookup,
    employee,
    attachment
  }
})

export default store
